import { render, staticRenderFns } from "./SolutionShow.vue?vue&type=template&id=363ca726&scoped=true&"
import script from "./SolutionShow.vue?vue&type=script&lang=js&"
export * from "./SolutionShow.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "363ca726",
  null
  
)

export default component.exports